










































import { Mixin_list } from "@/mixin";
import { api_settings, settings } from "@/http/settings";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";

@Component({
  components: {
    Page,
    Table,
  },
})
export default class extends Mixin_list<settings>(api_settings.get_list) {
  filter = {
    id: "",
    phone: "",
    nickname: "",
    user_type: "",
    // create_time_before: "",
    // create_time_after: "",
    status: "",
  };
  resetFilter = {
    ...this.filter,
  };
  columns = [
    {
      label: "ID",
      prop: "id",
    },
    {
      label: "入库拍照数量",
      prop: "put_num",
    },
    {
      label: "出库拍照数量",
      prop: "get_num",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "更新时间",
      prop: "update_time",
    },
    {
        label: "操作",
        prop: "",
        width: 300,
        slot: "operation",
      },
  ];
  handleClick(row:settings) {}
  // async handleClick( row:user){
  //     this.$confirm('确认升级吗?').then(async()=>{
  //         await api_user.edit({id:row.id,user_level:row.user_level === 1 ? 2 : row.user_level === 2 ? 3 : 0})
  //         this.get_list()
  //     })
  // }

  click_cell() {
    // return this.$router.push(`/user/detail/?id=${row.id}`);
  }
}
